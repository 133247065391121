@import './animations';
@import './mixins';
@import './variables';

$horizontal-padding: 10rem;
$section-spacing: 10vh;

.main {
    overflow: hidden;
}

// 1. Header section
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem $horizontal-padding;
    
    &__logo {
        height: 5rem;
    }

    &__nav-links {
        list-style: none;
        display: flex;
        align-items: center;
    }

    &__nav-link-item {
        cursor: pointer;
        display: inline-block;

        &:not(:last-child) {
            margin-right: 3rem;
            font-weight: normal;
            color: $color-gray;
            text-transform: uppercase;
        }
    }
}

// 2. Hero section
.hero {
    display: flex;
    align-items: center;
    padding: 5rem $horizontal-padding;
    min-height: 80vh;
    @include image-covered-background('/assets/images/hero.jpg', .5);

    &__content {
        > * {
            margin-bottom: 3rem;
        }

        width: 50%;
    }

    &__header {
        color: $color-primary;
        font-size: 6rem;
        font-weight: bold;
    }

    &__description {
        color: $color-white;
        max-width: 80ch;
        line-height: 1.5;
    }
}

// 3. Services section
.services {
    display: flex;
    justify-content: space-evenly;
    margin-top: -5vh;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    // TODO: Define it when RWD arrives
    max-width: 1800px;

    .card + .card {
        margin-left: 1rem;
    }
}

// 4. About us section
.about-us {
    margin-top: $section-spacing;
    padding: 0 $horizontal-padding;

    .description-item:nth-child(2) {
        flex-direction: row-reverse;

        .description-item__content {
            padding-left: 0;
        }
    }
    
    .description-item + .description-item {
        margin-top: $section-spacing;
    }

    .check-mark-list {
        margin-top: 1em;
    }
}

// 5. Gallery
.gallery {
    margin-top: $section-spacing;
    padding: 0 $horizontal-padding;

    .section-header {
        margin-bottom: $section-spacing;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
    }

    &__content-item {
        flex: 1 1 33%;
        max-width: 33%;
        overflow: hidden;

        img {
            transform: scale(1);
            transition: 300ms ease-in-out;

            &:hover {
                transform: scale(1.3);
            }
        }
    }
}

// 6. Footer
.footer {
    display: flex;
    flex-wrap: wrap;
    background: $color-dark;
    color: $color-white;
    line-height: 1.5;
    margin-top: $section-spacing;
    padding: 5rem $horizontal-padding;

    &__section {
        flex: 1;
    }

    h2.section-header {
        margin-bottom: 1em;
    }

    .our-company {
        &__paragraph {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 1em;
            }

            i.fas {
                margin-right: .75em;
                color: $color-primary;
            }
        }
    }

    .map {
        height: 40rem;
        overflow: hidden;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}

// 7. Copyrights
.copyrights {
    color: $color-white;
    background: $color-dark;
    font-size: 1.2rem;
    padding: 0 $horizontal-padding;

    &__content {
        border-top: 3px solid $color-primary;
        padding: 1em 0;
    }
}
