@import 'mixins';
@import 'variables';

$desktop-xl: 1680px;
$breakpoint-tablet: 768px;
$breakpoint-tablet-xl: 1024px;
$breakpoint-mobile: 600px;
$breakpoint-mobile-xs: 320px;

// Desktop
@include respond($desktop-xl) {
    $horizontal-padding: 5rem;
    $line-thickness: 3px;
    
    .header, .hero, .about-us, .gallery, .footer, .copyrights {
        padding-left: $horizontal-padding;
        padding-right: $horizontal-padding;
    }

    body {
        font-size: 1.125rem;
    }

    .header {
        &__logo {
            height: 3.5rem;
        }
    }

    .hero {
        &__header {
            font-size: 3.5rem;
        }

        &__content {
            > * {
                margin-bottom: 1.125rem;
            }

            width: 60%;
        }
    }

    .section-header {
        font-size: 2.5rem;

        &--underlined::after, &--dividing::before, &--dividing::after {
            height: 3px;
        }
    }

    .footer {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
    }

    .copyrights {
        font-size: 1rem;
    }

    .card {
        padding: 1em;
        width: 15rem;
    }
}

// Tablet
@include respond($breakpoint-tablet-xl) {
    $horizontal-padding: 3.5rem;
    $section-spacing: 5vh;

    .header, .hero, .about-us, .gallery, .footer, .copyrights {
        padding-left: $horizontal-padding;
        padding-right: $horizontal-padding;
    }

    .about-us, .gallery, .footer {
        margin-top: $section-spacing;
    }

    .hero {
        &__content {
            width: 100%;
        }
    }

    .services {
        padding: 0 $horizontal-padding;
        align-items: center;
        flex-wrap: wrap;

        .card {
            font-size: inherit;
            flex: 1 1 40%;
            max-width: 40%;
            margin-bottom: 2.5rem;
        }

        .card + .card {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .about-us {
        .description-item {
            &__image, &__content {
                flex-basis: 100%;
                width: 100%;
            }

            &__image {
                margin-bottom: 1.5em;
            }

            &__content {
                padding: 0;
            }
        }

        .description-item + .description-item {
            margin-top: $section-spacing;
        }
    }

    .gallery {
        .section-header {
            margin-bottom: $section-spacing;
        }

        &__content-item {
            flex-basis: 50%;
            max-width: 50%;
        }
    }

    .footer {
        &__section {
            flex: 1 1 100%;
        }

        .our-company {
            margin-bottom: 2.5rem;
        }
    }
}

// Mobile
@include respond($breakpoint-mobile) {
    $horizontal-padding: 2.5rem;
    $section-spacing: 5vh;

    body {
        font-size: 1rem;
        line-height: 1.2;
    }

    .section-header {
        font-size: 2.25rem;

        &--underlined::after, &--dividing::before, &--dividing::after {
            height: 3px;
        }
    }

    .header {
        &__nav {
            display: none;
        }
    }

    .hero {
        &__header {
            font-size: 2.5rem;
        }

        &__content {
            width: 100%;
        }
    }

    .services {
        flex-direction: column;
        align-items: center;

        .card {
            font-size: inherit;
            max-width: 100%;

            &__image {
                height: 8em;
            }
        }

        .card + .card {
            margin-left: 0;
            margin-top: 1rem;
        }
    }
    
    .gallery {
        .section-header {
            margin-bottom: $section-spacing;

            &--dividing {
                &::before, &::after {
                    width: 20%;
                }
            }
        }

        &__content-item {
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    .footer {
        padding: $horizontal-padding 0;

        h2.section-header {
            margin-bottom: .75em;
        }

        .our-company {
            padding-left: $horizontal-padding;
            padding-right: $horizontal-padding;
        }

        .map {
            height: 20rem;
        }
    }

    .copyrights {
        font-size: .75rem;
    }
}

@include respond($breakpoint-mobile-xs) {
    $horizontal-padding: 1.5rem;

    body {
        font-size: .75rem;
    }

    .header, .hero, .about-us, .gallery, .footer, .footer .our-company, .copyrights {
        padding-left: $horizontal-padding;
        padding-right: $horizontal-padding;
    }

    .hero {
        &__header {
            font-size: 2rem;
        }
    }

    .about-us {
        padding: 0;

        .section-header {
            font-size: 1.75rem;
        }

        .description-item__content, .description-item:nth-child(2) .description-item__content {
            padding: 0 $horizontal-padding;
        }
    }
}
