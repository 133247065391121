@import './mixins';
@import './variables';

.btn {
    display: inline-block;
    padding: .5em 1em;
    border-radius: 5rem;
    box-shadow: 0 3px 3px rgba(#000, .15);
    text-transform: uppercase;
    cursor: pointer;
    transition: box-shadow 250ms;

    &--primary {
        background: $color-primary;
        color: $color-white;
    }

    &:hover {
        box-shadow: 0 3px 8px rgba(#000, .35);
        color: $color-white;
    }
}

.section-header {
    color: $color-primary;
    font-weight: normal;

    $line-thickness: 5px;

    &--underlined {
        display: inline-block;
        margin-bottom: .5em;
        @include pseudo(after) {
            left: 0;
            bottom: -.15em;
            width: 65%;
            height: $line-thickness;
            background: $color-primary;
        }
    }

    &--dividing {
        display: block;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 1em;

        @mixin divider-line {
            top: 50%;
            width: 30%;
            height: $line-thickness;
            background: $color-primary;
        }

        @include pseudo(before) {
            @include divider-line;
            left: 5%;
        }

        @include pseudo(after) {
            @include divider-line;
            right: 5%;
        }
    }
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    width: 18rem;
    background: #fff;
    box-shadow: 1px 3px 10px rgba(#000, .25);
    font-size: 1.25rem;
    transition: all 300ms;

    &__image {
        height: 10em;
        margin-bottom: .5em;
    }

    &__name {
        text-align: center;
        color: $color-gray;
    }

    &:hover {
        transform: translateY(-1rem);
        box-shadow: 1px 3px 15px rgba(#000, .45);
    }
}

.description-item {
    display: flex;
    flex-wrap: wrap;

    &__image, &__content {
        flex: 1 1 50%;
        width: 50%;
    }

    &__image {
        object-fit: cover;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__content {
        padding: 0 3em;
    }

    &__text {
        max-width: 65ch;
        line-height: 1.5;

        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }
}

.check-mark-list {
    list-style: none;

    li {
        padding: 1em;
        padding-left: 1.5em;
        background: $color-white;
        box-shadow: 1px 3px 10px rgba(#000, .25);

        @include pseudo(before) {
            content: '\2713';
            text-align: center;
            color: $color-white;
            font-weight: bold;
            top: -0.5em;
            left: -0.75em;
            width: 1.5em;
            height: 1.5em;
            background: $color-primary;
            border-radius: 50%;
        }
    }

    li + li {
        margin-top: 1em;
    }
}
