// 1. RWD
@mixin respond($breakpoint) {
    @media only screen and (max-width: $breakpoint) {
        @content;
    }
}

// 2. Pseudoelements
@mixin pseudo($location, $position: absolute) {
    position: relative;

    &::#{$location} {
        content: '';
        position: $position;
        @content;
    }
}

// 3. Utils
@mixin center-absolutely {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin image-covered-background($url, $overlay-opacity: 1) {
    background: url($url);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;

    @include pseudo(after) {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(#000, $overlay-opacity);
        z-index: -1;
    }
}
