@font-face { 
    font-family: 'Roboto'; 
    src: url('/assets/fonts/Roboto-Thin.ttf') format("truetype");
    font-weight: 100;
}

@font-face { 
    font-family: 'Roboto'; 
    src: url('/assets/fonts/Roboto-ThinItalic.ttf') format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face { 
    font-family: 'Roboto'; 
    src: url('/assets/fonts/Roboto-Light.ttf') format("truetype");
    font-weight: 300;
}

@font-face { 
    font-family: 'Roboto'; 
    src: url('/assets/fonts/Roboto-LightItalic.ttf') format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face { 
    font-family: 'Roboto'; 
    src: url('/assets/fonts/Roboto-Regular.ttf') format("truetype");
    font-weight: 400;
}

@font-face { 
    font-family: 'Roboto'; 
    src: url('/assets/fonts/Roboto-Regular.ttf') format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face { 
    font-family: 'Roboto'; 
    src: url('/assets/fonts/Roboto-Medium.ttf') format("truetype");
    font-weight: 500;
}

@font-face { 
    font-family: 'Roboto'; 
    src: url('/assets/fonts/Roboto-MediumItalic.ttf') format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face { 
    font-family: 'Roboto'; 
    src: url('/assets/fonts/Roboto-Bold.ttf') format("truetype");
    font-weight: 700;
}

@font-face { 
    font-family: 'Roboto'; 
    src: url('/assets/fonts/Roboto-BoldItalic.ttf') format("truetype");
    font-weight: 700;
    font-style: italic;
}

@font-face { 
    font-family: 'Roboto'; 
    src: url('/assets/fonts/Roboto-Black.ttf') format("truetype");
    font-weight: 900;
}

@font-face { 
    font-family: 'Roboto'; 
    src: url('/assets/fonts/Roboto-BlackItalic.ttf') format("truetype");
    font-weight: 900;
    font-style: italic;
}

h1 {
    font-size: 4rem;
}
